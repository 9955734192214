import React, {useContext} from 'react'
import styled from 'styled-components'
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Header from "@common/menus/header"
import { FirebaseContext } from '@components/Firebase'
import readingTime from 'reading-time/lib/reading-time';
import logoMobile from '@images/logos/logo-mobile.svg';
import BackToAppButton from "@common/BackToAppButton"
import Seo from '../../components/Seo';


const Wrapper = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding:25px min(25px,3%);
  box-sizing: border-box;
  >div{
    max-width: min(825px,100%);
    margin-top:${props => props.user ? 0 : 50}px;
    >h1{
      font-size:2rem;
      text-align:center;
      @media (min-width: 800px ) {
        font-size:5rem;
        text-align:left;
        margin:20px 0;
      }
    }
  }
`

const PostList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: min(25px,3vw);
  row-gap: min(25px,3vw);
  @media (min-width: 800px ) {
    grid-template-columns:  1fr 1fr;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const PostImageWrapper = styled.div`
  max-width:130px;
   @media (min-width: 800px ) {
    max-width:130px;
  }
  border-radius:7px;
  overflow: hidden;
`

const PostWrapper =  styled.article`
  background-color: white;
  border-radius: 15px;
  padding: 15px;
  width:100%;
  min-height:160px;
  display:flex;
  justify-content: space-between;
  small{
    font-family: 'Poppins';
    font-size:0.8rem;
  }
  &:hover{
    transform:scale(1.01);
  }
  transition: 100ms ease-in-out;
`

const PostMetaWrapper = styled.div`
  position:relative;
  padding-top:5px;
  height:100%;
  min-width: 160px;
  >small{
    position: absolute;
    bottom:5px;
    font-size:12px;
    color:#7B7676;
  }
  h2{
    margin:0;
    font-size: 1.3rem;
    @media (max-width: 375px ) {
      font-size: 1.2rem;
    }
  }
`

const Author = styled.div`
  display:flex;
  align-items:center;
  margin-bottom:10px;
  >img{
    height:25px;
    width:25px;
    margin-right:10px;
    border-radius:50%;
  }
  >h3{
    font-family:'Poppins';
    font-size: 14px;
    margin:0;
  }
`

const FeaturedImageWrapper = styled(PostImageWrapper)`
  @media (min-width: 800px ) {
    max-width:270px;
    border-radius:7px;
    overflow: hidden;
  }
`

const FeaturedMetaWrapper = styled(PostMetaWrapper)`
  >p{
      display:none;
    }
  @media (min-width: 800px ) {
    max-height:400px;
    padding:2.5%;
    >p{
      display:block;
    }
    h2{
      font-size: 2rem;
    }
  }
`

const FeaturedWrapper = styled(PostWrapper)`
  @media (min-width: 800px ) {
    grid-column: 1 / span 2;
    height:300px;
  }
`

const HeaderWrapper = styled.div`
  margin:30px 0 50px 0;
  h2{
    font-family:"Poppins";
    font-size:1.1rem;
    margin:5px 0 0 0;
    @media (min-width: 800px ) {
      font-size:2rem;
    }
  }
  >div{
    display: flex;
    align-items:center;
    h1{
      font-size:2rem;
      margin:0px 0;
      @media (min-width: 800px ) {
        font-size:5rem;
        text-align:left;
      }
    }
    img {
      height:40px;
      width: 40px;
      margin-right:10px;
      @media (min-width: 800px ) {
        height:80px;
        width: 80px;
        margin-right:20px;
      }
    }
  }
`;


const Wordpress = (props) => {
  const {allWpPost} = props.data
  const featuredPost = allWpPost.nodes[0]
 
  const {user} = useContext(FirebaseContext);

  const featuredImage = {
    data: featuredPost?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredPost?.featuredImage?.node?.alt || ``,
  }
  return (
  <>
    <Seo title="Blog" description="Sex Blog to Widen Your Horizons ✅ Happy Sex Life Through Sexual Wellness ✅ Mindful Sex Education"/>
    <Header boxLayout={false} playerMode={'small'} user={user} alwaysShow={true}/>
    <Wrapper user={user}> 
      <div>
        <HeaderWrapper>
          <div>
            <img src={logoMobile} alt="audiodesires - logo"/>
            <h1>Between the Sheets</h1>
          </div>
          <h2>Sex, intimacy, and erotic audio</h2>
        </HeaderWrapper>
        <PostList>
          <FeaturedWrapper>
          <FeaturedMetaWrapper>
            <Author>
              <img src={featuredPost.author.node.avatar.url} alt='author avatar'></img>
              <h3>{featuredPost.author.node.name}</h3>
            </Author>
            <StyledLink to={`${featuredPost?.slug}`}><h2>{featuredPost.title}</h2></StyledLink>
            <p dangerouslySetInnerHTML={{__html:`${featuredPost.excerpt.slice(3, 75)}...`}}/>
            <small>{featuredPost.date} • {parseInt(readingTime(featuredPost.content).minutes)} min read</small>
            </FeaturedMetaWrapper>
            <FeaturedImageWrapper> 
            {featuredImage?.data && (
                <GatsbyImage
                  image={featuredImage.data}
                  alt={featuredImage.alt}
                />
              )}
            </FeaturedImageWrapper>
  
          </FeaturedWrapper>
          {allWpPost.nodes.sort().filter((item,index) => index !== 0).map((post) => (
            <PostWrapper
              className="post-list-item"
              itemScope
              itemType="http://schema.org/Article"
              key={post.id}
            >
              <PostMetaWrapper>
                  <Author>
                    <img src={post.author.node.avatar.url} alt='author avatar'></img>
                    <h3>{post.author.node.name}</h3>
                  </Author>
                  <StyledLink to={`${post?.slug}`}><h2>{post?.title}</h2></StyledLink> 
                  <small>{post.date} • {parseInt(readingTime(post.content).minutes)} min read</small>
              </PostMetaWrapper>
                <PostImageWrapper>
                  <StyledLink to={`${post?.slug}`}>
                    <GatsbyImage
                      image={post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData}
                      alt={"asdfsdf"}
                    />
                  </StyledLink>
                </PostImageWrapper>
                
            </PostWrapper>
          ))}
        </PostList>
        {user && 
          <BackToAppButton/>
        }
      </div>
    </Wrapper>
  </>
  )
}

export default Wordpress

export const query = graphql`{
  allWpPost(
    sort: {
      fields: [date]
      order: [DESC]
    }) {
    nodes {
      id
      isSticky
      title
      content
      slug
      excerpt
      date(formatString: "MMM DD")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: CONSTRAINED
                aspectRatio:1
              )
            }
          }
        }
      }
      author {
        node {
          name
          avatar {
            url
          }
        }
      }
    }
  }
}`
