import React, { useContext } from 'react'
import styled from 'styled-components'
import AudioPlayerContext from '@components/audioplayer/AudioPlayerContext'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'

const BackButton = styled.button`
  z-index:999;
  padding: 15px 60px;
  position:fixed;
  bottom:${props => props.playerMode === "small" ? "200" : "100"}px;
  right:30px;
  background-color:${props => props.theme.secondaryColor};
  border-radius:25px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  text-transform: uppercase;
  color:white;
  border: solid 4px white;
  font-size:1rem;
  @media ${props => props.theme.tabletBelow} {
    border: solid 2px white;
    padding: 10px 20px;
    bottom:${props => props.playerMode === "small" ? "80" : "30"}px;
  }
 `


const BackToAppButton = (props) => {
  const {playerMode} = useContext(AudioPlayerContext);
  const { t } = useTranslation();

  return (
    <BackButton playerMode={playerMode} onClick={() => navigate(t('urls.home'))}>
        Back to App
    </BackButton>
  )
}

export default BackToAppButton