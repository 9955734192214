/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { usePageContext } from '@hooks/usePageContext'


function Seo({ description, meta, title, structuredData, image}) {
  const {lang, paths, languages} = usePageContext()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            supportedLanguages
            siteUrl
          }
        }
      }
    `
  )
  
  const metaImage = image ? `${site.siteMetadata.siteUrl}${image}` : `${site.siteMetadata.siteUrl}/images/og-fallback.jpg`
 
  const metaDescription = description || site.siteMetadata.description
  return (
    
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s ― ${site.siteMetadata.title}`}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: metaImage,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: `twitter:image`,
            content: metaImage,
          },
        ].concat(meta)}
      > 
    <link 
        rel='alternate'
        hrefLang= 'x-default'
        href=  {`${site.siteMetadata.siteUrl}${paths.originalPath}`} 
      /> 
      {languages.map((supportedLang, index) => {
        // uncomment if to prevent self link
        // return supportedLang != lang && 
        return (
          <link 
            key={index}
            rel='alternate'
            hrefLang= {`${supportedLang}`}
            href=  {`${site.siteMetadata.siteUrl}${paths[supportedLang]}`} 
          />
          )
      })} 
      {structuredData && structuredData.map((data, index) => { 
        return ( 
          <script type="application/ld+json" key={index}>
          {JSON.stringify(data)}
          </script>
        )
      })}
      <script src="https://cdn.lovense.com/peditor/videoPattern.js"></script>
      </Helmet>
    )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
